var MainNavigation = {

    toggleMobileExecuteDesktop: function () {
        $('.u-toggleMobileExecuteDesktop').on('click', function (event) {
            if (breakpoint.value === "xs" || breakpoint.value === "sm" || breakpoint.value === "md") {
                var $this = $(this),
                    $target = $this.parent().find(".u-toggleMobileExecuteDesktopTarget").first(),
                    visibleClass = "is-visible";

                if ($target.hasClass(visibleClass)) {
                    $target.removeClass(visibleClass);

                    $target.find("." + visibleClass).removeClass(visibleClass); // Close all open child menus
                    return false;
                }
                $this.parent().siblings().find("." + visibleClass).removeClass(visibleClass); // Close all open sibling menus
                $target.addClass(visibleClass);
                return false;
            }
        })
    },

    toggleDesktopExecuteMobile: function () {
        $('.u-toggleDesktopExecuteMobile').on('click', function (event) {
            if (breakpoint.value === "lg" || breakpoint.value === "xl") {
                var $this = $(this),
                    $target = $this.parent().find(".u-toggleDesktopExecuteMobileTarget").first(),
                    visibleClass = "is-visible";

                if ($target.hasClass(visibleClass)) {
                    $target.removeClass(visibleClass);
                    $target.find("." + visibleClass).removeClass(visibleClass); // Close all open child menus
                    return false;
                }
                $this.parent().siblings().find("." + visibleClass).removeClass(visibleClass); // Close all open sibling menus
                $target.addClass(visibleClass);
                return false;
            }
        })
    },
    toggleAll: function () {
        $('.u-toggleAll').on('click', function (event) {
            var $this = $(this),
                $target = $this.parent().find(".u-toggleAllTarget").first(),
                visibleClass = "is-visible";

            if ($target.hasClass(visibleClass)) {
                $target.removeClass(visibleClass);
                $target.find("." + visibleClass).removeClass(visibleClass); // Close all open child menus
                return false;
            }
            $this.parent().siblings().find("." + visibleClass).removeClass(visibleClass); // Close all open sibling menus
            $target.addClass(visibleClass);

            MainNavigation.expandFirstChildIfCurrentItemIsFirstMegaMenuItem($target);

            return false;
        });
    },

    expandFirstChildIfCurrentItemIsFirstMegaMenuItem: function ($target) {
        let visibleClass = "is-visible";
        if ($target.closest('li').index("li.nav-item.dropdown.MainNavigation-navItem.MainNavigation-navItem--megaMenu") === 0) {
            let $child = $target.find(".u-toggleAllTarget").first();
            $child.addClass(visibleClass);
        }
    },

    openClose: function () {
        $('body').on('click', function (e) {
            if (!$('.nav-item').is(e.target) && $('.nav-item').has(e.target).length === 0 && $('.is-visible').has(e.target).length === 0) {
                $('.nav-item').removeClass('is-visible');
                $('.dropdown-menu').removeClass('is-visible');
            }
        });
    },

    setupLanguageLink: function () {
        $('.MainNavigation-languageLink').click(function (e) {
            if ($(this).data("siteurl") || $(this).data("language")) {
                e.preventDefault();
                MainNavigation.setLanguageCookie();
                MainNavigation.navigateToLanguage(this);
            }
          
        });
    },
    setLanguageCookie: function () {
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = "userlanguage=;" + expires + "; path=/";
    },
    navigateToLanguage: function (linkElement) {

        var siteUrl = $(linkElement).data("siteurl");        
        var newLanguageCode = $(linkElement).data("language");
        var contextItemId = $('body').data('ctxitem');
        var languageCode = $('body').data('ctxlanguage');

        if (siteUrl.length > 0) {
            window.location = siteUrl;
        }
        else {
            $.ajax({
                url: '/api/navigation/language',
                data: {
                    contextItemId: contextItemId,
                    newLanguageCode: newLanguageCode,
                    languageCode: languageCode
                }
            }).done(function (data) {
                window.location = data.uri;
            });
        }

    },

    

    init: function () {
        MainNavigation.openClose();
        MainNavigation.toggleMobileExecuteDesktop();
        MainNavigation.toggleDesktopExecuteMobile();
        MainNavigation.toggleAll();
        MainNavigation.setupLanguageLink();
    }
}

$(function () {
    MainNavigation.init();
});
